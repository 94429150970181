









































































































































import { useBlob, useGeneralJournal } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { DATE_FORMAT_DD_MM_YYYY_HH_MM_SS } from "@/models/constants/date.constant";
import { GENERAL_JOURNAL_SOURCE } from "@/models/enums/general-journal.enum";
import { Messages } from "@/models/enums/messages.enum";
import {
  DetailGeneralJournal,
  JournalLineDTOS,
} from "@/models/interface/generaljournal.interface";
import { generalJournalServices } from "@/services/generaljournal.service";
import { currencyFormat } from "@/validator/globalvalidator";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import { Decimal } from "decimal.js-light";
import moment from "moment";
import printJS from "print-js";
import Vue from "vue";

export default Vue.extend({
  name: "DetailJournal",
  mixins: [MNotificationVue],
  data() {
    return {
      DATE_FORMAT_DD_MM_YYYY_HH_MM_SS,
      totalData: 0 as number,
      limit: 10 as number,
      detailJournal: {
        journalLineDTOS: [] as JournalLineDTOS[],
        source: "",
      } as DetailGeneralJournal,
      loading: {
        delete: false,
        table: false,
        print: false,
      },
      urlAttachment: "" as string,
      totalCreditValue: "" as string,
      totalDebitValue: "" as string,
      columnsTable: [
        {
          title: this.$t("lbl_account_name"),
          dataIndex: "accountDescription",
          key: "accountName",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_code"),
          dataIndex: "accountCode",
          key: "code",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_currency"),
          dataIndex: "currency",
          key: "currency",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_rates"),
          dataIndex: "currencyRates",
          key: "currencyRates",
          width: 100,
          scopedSlots: { customRender: "isNull" },
        },
        {
          title: this.$t("lbl_debit"),
          dataIndex: "debit",
          key: "debit",
          width: 100,
          scopedSlots: { customRender: "isCurrency" },
        },
        {
          title: this.$t("lbl_credit"),
          dataIndex: "credit",
          key: "credit",
          width: 100,
          scopedSlots: { customRender: "isCurrency" },
        },
      ],
      formRules: {
        docNumber: {
          label: "lbl_document_number",
          name: "docNumber",
          placeholder: "lbl_document_number",
          decorator: [
            "docNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        transactionDate: {
          label: "lbl_transaction_date",
          name: "Transaction Date",
          placeholder: "lbl_transaction_date",
          decorator: [
            "transactionDate",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        docReference: {
          label: "lbl_document_reference",
          name: "docReference",
          placeholder: "lbl_document_reference",
          decorator: [
            "docReference",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        status: {
          label: "lbl_status",
          name: "status",
          placeholder: "lbl_status",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        source: {
          label: "lbl_source",
          name: "source",
          placeholder: "lbl_source",
          decorator: [
            "source",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "lbl_description",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
      idJournal: "",
      form: this.$form.createForm(this, {
        name: "generaljournal",
      }),
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
    isManual(): boolean {
      return (
        this.detailJournal.source.toUpperCase() ===
        GENERAL_JOURNAL_SOURCE.MANUAL.toUpperCase()
      );
    },
  },
  created(): void {
    if (this.$route.params.id) {
      this.idJournal = this.$route.params.id;
      this.getDetailGeneralJournal(this.idJournal);
    }
  },
  methods: {
    moment,
    handleBack(): void {
      this.$router.push({ name: "generaljournal.read" });
    },
    getDetailGeneralJournal(id: string): void {
      this.loading.table = true;
      generalJournalServices
        .detailGeneralJournal(id)
        .then(res => {
          this.urlAttachment = res.attachment || "";
          this.detailJournal = res;
          this.detailJournal.journalLineDTOS.forEach(
            (line, i) => (line["key"] = i)
          );
          this.setForm(this.detailJournal);
          this.setTotal();
        })
        .finally(() => (this.loading.table = false));
    },
    setForm(res: DetailGeneralJournal): void {
      const form = this.form as WrappedFormUtils;
      form.setFieldsValue({
        docNumber: res.name,
        docReference: res.documentReference,
        transactionDate: moment(res.date),
        status: res.status,
        source: res.source,
        description: res.description,
      });
    },
    setTotal(): void {
      this.totalCreditValue = currencyFormat(
        this.detailJournal.journalLineDTOS.reduce(
          (a, b) => new Decimal(b.credit).plus(a).toNumber(),
          0
        )
      );
      this.totalDebitValue = currencyFormat(
        this.detailJournal.journalLineDTOS.reduce(
          (a, b) => new Decimal(b.debit).plus(a).toNumber(),
          0
        )
      );
    },
    handlePrint(): void {
      const { toObjectUrl } = useBlob();
      const { print } = useGeneralJournal();
      this.loading.print = true;
      print(this.idJournal)
        .then(response => {
          printJS(toObjectUrl(response));
        })
        .finally(() => {
          this.loading.print = false;
        });
    },
    confirmDelete() {
      this.showConfirmationCancel(() => this.handleDelete(this.idJournal));
    },
    handleDelete(secureId: string) {
      const { deleteJournal } = useGeneralJournal();
      this.loading.delete = true;
      deleteJournal(secureId)
        .then(() => {
          this.showNotifSuccess("notif_delete_success");
          this.$router.push({ name: "generaljournal.read" });
        })
        .finally(() => {
          this.loading.delete = false;
        });
    },
  },
});
