var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_journal_detail") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form",
                _vm._b(
                  {
                    attrs: {
                      layout: "vertical",
                      "label-align": "left",
                      form: _vm.form
                    }
                  },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.docNumber.label) } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.docNumber.decorator,
                            expression: "formRules.docNumber.decorator"
                          }
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            _vm.formRules.docNumber.placeholder
                          ),
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.transactionDate.label)
                      }
                    },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.transactionDate.decorator,
                            expression: "formRules.transactionDate.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          placeholder: _vm.$t(
                            _vm.formRules.transactionDate.placeholder
                          ),
                          disabled: true,
                          format: _vm.DATE_FORMAT_DD_MM_YYYY_HH_MM_SS
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.source.label) } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.source.decorator,
                            expression: "formRules.source.decorator"
                          }
                        ],
                        attrs: {
                          placeholder: _vm.$t(_vm.formRules.source.placeholder),
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.docReference.label) }
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.docReference.decorator,
                            expression: "formRules.docReference.decorator"
                          }
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            _vm.formRules.docReference.placeholder
                          ),
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.status.label) } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.status.decorator,
                            expression: "formRules.status.decorator"
                          }
                        ],
                        attrs: {
                          placeholder: _vm.$t(_vm.formRules.status.placeholder),
                          disabled: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.description.label) },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [
                              _c("character-length", {
                                attrs: {
                                  value:
                                    _vm.form.getFieldValue("description") || ""
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("a-textarea", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.description.decorator,
                            expression: "formRules.description.decorator"
                          }
                        ],
                        attrs: {
                          placeholder: _vm.$t(
                            _vm.formRules.description.placeholder
                          ),
                          rows: 4,
                          disabled: ""
                        }
                      })
                    ],
                    1
                  ),
                  _vm.urlAttachment
                    ? _c(
                        "a-form-item",
                        { attrs: { label: _vm.$t("lbl_attachment") } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "" + _vm.urlAttachment,
                                rel: "noopener noreferrer",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.urlAttachment || "-") + " "
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("a-col", { attrs: { span: 24 } }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("lbl_journal_lines")))])
          ]),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  size: "small",
                  idtable: "table1",
                  onSelectChange: null,
                  selectedRowKeys: null,
                  dataSource: _vm.detailJournal.journalLineDTOS,
                  columns: _vm.columnsTable,
                  scroll: { x: "calc(700px + 50%)", y: 700 },
                  paginationcustom: true,
                  defaultPagination: false,
                  loading: _vm.loading.table
                }
              }),
              _c("p", { staticClass: "text-right mt-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("lbl_total_items", {
                        total: _vm.detailJournal.journalLineDTOS.length
                      })
                    ) +
                    " "
                )
              ])
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("a-statistic", {
                attrs: {
                  title: _vm.$t("lbl_debit"),
                  value: _vm.totalDebitValue || 0
                },
                scopedSlots: _vm._u([
                  {
                    key: "formatter",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("a-statistic", {
                attrs: {
                  title: _vm.$t("lbl_credit"),
                  value: _vm.totalCreditValue || 0
                },
                scopedSlots: _vm._u([
                  {
                    key: "formatter",
                    fn: function(ref) {
                      var value = ref.value
                      return [
                        _vm._v(" " + _vm._s(_vm._f("currency")(value)) + " ")
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _vm.$can("delete", "general-journal") &&
                  _vm.idJournal &&
                  _vm.isManual
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "danger",
                            loading: _vm.loading.delete
                          },
                          on: { click: _vm.confirmDelete }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete")) + " ")]
                      )
                    : _vm._e(),
                  _c(
                    "a-button",
                    {
                      attrs: { loading: _vm.loading.print, type: "primary" },
                      on: { click: _vm.handlePrint }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }